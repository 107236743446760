module.exports = [{
      plugin: require('../node_modules/_gatsby-plugin-smoothscroll@1.2.0@gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/_gatsby-plugin-catch-links@2.10.0@gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/_gatsby-plugin-mdx@1.10.1@gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/victor/project/docs/src/layouts/articleLayout.tsx"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-sectionize",{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"<svg width=\"17\" height=\"18\" viewBox=\"0 0 17 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n      <path d=\"M1.5 6.33337H15.5\" stroke=\"#CBD5E0\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"/>\n      <path d=\"M1.5 11.6666H15.5\" stroke=\"#CBD5E0\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"/>\n      <path d=\"M6.75 1L5 17\" stroke=\"#CBD5E0\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"/>\n      <path d=\"M12 1L10.25 17\" stroke=\"#CBD5E0\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"/>\n      </svg>","className":"title-link","enableCustomId":true}},{"resolve":"gatsby-remark-images","options":{"disableBgImageOnAlpha":true}},{"resolve":"/home/victor/project/docs/plugins/gatsby-remark-to-absoluteurl/index.js","options":{"redirects":[{"from":"/reference/tools-and-interfaces/prisma-schema/prisma-schema-file","to":"/reference/tools-and-interfaces/prisma-schema"},{"from":"/reference/tools-and-interfaces/prisma-schema","to":"/concepts/components/prisma-schema"},{"from":"/reference/tools-and-interfaces/prisma-client/api","to":"/concepts/components/prisma-client"},{"from":"/reference/tools-and-interfaces/prisma-schema/models","to":"/reference/tools-and-interfaces/prisma-schema/data-model#defining-models"}]}},{"resolve":"/home/victor/project/docs/plugins/gatsby-remark-check-links-numberless/index.js"},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static"}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/victor/project/docs"},
    },{
      plugin: require('../node_modules/_gatsby-plugin-google-tagmanager@2.11.0@gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KCGZPWB","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"website":"docs"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
